export default (model) => ({
  navigate: (attrs, path) => {
    path = path.split(/[?#]/, 1)[0]
    model.direction = model.path
      ? model.routesOrder.indexOf(path) > model.routesOrder.indexOf(model.path) ? 1 : -1
      : 0

    model.path = path
  }
})
