import b from 'bss'
import m from 'mithril'

export default () =>


  m('div' + b `
    background-image url( './images/background.jpg' )
    background-size cover
    background-repeat no-repeat
    mh 80vw
    p 100 10%
    `.landscape `
    p 10% 20%
    `
  ,
        m('h3',
        'Business music & digital signage'
        ),
        m('h1',
        'Velkommen til Beat'
        ),
        m('p',
        `
        Beat er markedets førende leverandør af professionelle løsninger inden for business music og
        digital signage. Vores løsninger bruges i de største danske detailkæder med over 5000
        butikker i hele verden, og vi har leveret musik til bar- og cafélivet i Danmark gennem de
        sidste 20 år.
        `
        ),
        m('p',
        `
        Vi hjælper virksomheder med at skabe stemning og atmosfære for deres kunder
        gennem lyd og digital visuelt indhold. Vores produkter bygger på vores egen teknologiske
        platform, som konstant er under udvikling for at kunne tilbyde nye spændende produkter og
        funktioner.
        `
        )
  )


