import b from 'bss'
import m from 'mithril'

import logoSvg from '../icons/logo.svg'
import icon from '../components/icon'

const nav = [{
  href: '/business-music',
  title: 'Business Music'
}, {
  href: '/digital-signage',
  title: 'Digital Signage'
}, {
  href: '/hardware',
  title: 'Hardware'
}]

const subNav = [{
  href: '/om',
  title: 'Om os'
}, {
  href: '/betingelser',
  title: 'Betingelser'
}/*, {
  href: 'https://support.beat.dk',
  taget: '_blank',
  title: 'Support'
}, {
  href: 'https://status.beat.dk',
  target: '_blank',
  title: 'Status'
}*/]

let scrolled = false
let showMenu = false

export default () =>
  m('header' + b`
    position fixed
    d flex
    fd column
    z-index 200
    h 60
    w 100%
    transition max-height 0.5s, background-color 0.3s
    bc rgba(0, 0, 0, 0.9)
    jc center
  `.landscape`
    max-height ${ scrolled ? 60 : 86 }
    h 100%
    bc ${ scrolled && 'rgba(0, 0, 0, 0.9)' }
  `, {
    key: 'header',
    oninit: () =>
      window.addEventListener('scroll', () => {
        const newScrolled = (document.documentElement.scrollTop || document.body.scrollTop) > 0
        newScrolled !== scrolled && m.redraw()
        scrolled = newScrolled
      })
  },
    m('div' + b`
      cursor pointer
      transform ${ (!b.isLandscape() || scrolled) && 'scale(0.75)' }
      transform-origin left center
      transition transform 0.5s
      p 0 32
      position absolute
      z-index 300
    `,
      icon({
        svg: logoSvg,
        onclick: () => {
          m.route.set('/'),
          showMenu = false
        },
        styles: b`
          h 40
          transition filter 0.3s, transform 0.3s
        `.$hover`
          transform translateY(-2px)
          filter brightness(150%)
        `
      })
    ),
    m('div' + b`
      position absolute
      cursor pointer
      d flex
      jc center
      ai center
      r 0
      h 100%
      w 60
      transition transform 0.3s, filter 0.3s
    `.landscape`
      d none
    `.$hover`
      transform translateY(-2px)
      filter brightness(150%)
    `, {
      onclick: () => showMenu = !showMenu
    },
      m('div' + b`
        position relative
        w 24
        h 2
        bc ${ b.colors.greyOnDark }
        transition transform 0.3s, background-color 0.3s
        ${ showMenu && `
          bc transparent
        `}
      `.$before`
        position absolute
        w 24
        h 2
        bc ${ b.colors.greyOnDark }
        transform translateY(7px)
        content " "
        transition transform 0.3s, background-color 0.3s
        ${ showMenu && `
          transform rotate(45deg) translateY(0)
        `}
      `.$after`
      position absolute
        content " "
        w 24
        h 2
        bc ${ b.colors.greyOnDark }
        transform translateY(-7px)
        transition transform 0.3s, background-color 0.3s
        ${ showMenu && `
          transform rotate(-45deg) translateY(0)
        `}
      `)
    ),
    m('nav.menu' + b`
      position absolute
      top 0
      d flex
      fd column
      h 100vh
      justify-content flex-start
      mt 60
      w 100%
      background rgba(0,0,0, 0.9)
      ${ !b.isLandscape() && `
        transition transform 0.5s, opacity 0.5s
        `}
      transform translateX(100vw)
      opacity 0
      ${ !b.isLandscape() && showMenu && `
        transform translateX(0)
        opacity calc(1)
      `}
    `.landscape`
      position relative
      h auto
      m 0
      opacity 1
      background none
      fd row
      jc flex-end
      transition transform 1s
      transform ${ scrolled ? 'scale(0.8)' : 'translateX(0)' }
      transform-origin 100% 50%
      transition transform 0.5s
      p 0 16
    `, {
      onclick: showMenu && (() => showMenu = false)
    },
      nav.map(({ href, title }) =>
        m('a' + b`
          c ${location.pathname === href ? b.colors.white : b.colors.greyOnDark }
          td none
          fs 8vw
          p 16 28
          tt uppercase
          ls 2
          transition transform 0.3s, color 0.3s
       `.landscape`
          fs ${ b.fontSizes.small }
          d flex
        `.$hover`
          color white
          transform translateY(-2px)
        `, {
          href,
          oncreate: m.route.link
        }, title)
      ),
      !b.isLandscape() && ([
        subNav.map(({ href, title }) =>
          m('a' + b`
            c ${location.pathname === href ? b.colors.white : b.colors.greyOnDark }
            td none
            fs 6vw
            p 16 28
            tt uppercase
            ls 2
            transition transform 0.3s, color 0.3s
            `.landscape`
              fs ${ b.fontSizes.sub }
            `, {
            href,
            oncreate: m.route.link
          }, title)
        )
      ])
    )
  )

