import b from 'bss'
import m from 'mithril'

const positions = 'absolute relative fixed static'

b.isLandscape = () => window.innerWidth >= 1024

window.addEventListener('resize', m.redraw)
window.addEventListener('orientationchange', m.redraw)

b.helper({
  landscape: (a, ...c) => b.$media('(min-width: 1024px)', b(a, ...c))
})

b.css('@font-face', `
  font-family Avenir
  font-weight normal
  font-display swap
  src local('Avenir Next'),
      url("/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix")
  src url("/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"),
      url("/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),
      url("/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),
      url("/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype")
`)

b.css('@font-face', `
  font-family Avenir
  font-weight 600
  font-display swap
  src local('Avenir Next'),
      url("/fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix")
  src url("/fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix") format("eot"),
      url("/fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),
      url("/fonts/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"),
      url("/fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype")
`)

b.css('@font-face', `
  font-family Avenir
  font-weight 700
  font-display swap
  src local('Avenir Next'),
      url("/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix")
  src url("/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"),
      url("/fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),
      url("/fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),
      url("/fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype")
`)

b.css('@font-face', `
  font-family Avenir
  font-weight bold
  font-display swap
  src local('Avenir Next'),
      url("/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix")
  src url("/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"),
      url("/fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),
      url("/fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"),
      url("/fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype")
`)

b.css({
  html: `
    // background linear-gradient(black, #1a1a1a)
    // backgrond-size 100% 100vh
    // background-color #202020
    background-color #0f0f0f
    box-sizing border-box
    ff Avenir
  `,
  '*, *:before, *:after': `
    box-sizing inherit
  `,
  body: b`
    m 0
    fs 18
    `.landscape`
      fs 20
  `,
  a: b`
    color inherit
    text-decoration none
  `.$hover`
    text-decoration underline
  `,
  'input, textarea, button': `
    ff inherit
    fs inherit
  `,
  p: `
    margin-block-start 0
    margin-block-end 0
    mb 32
  `
})

b.colors = {
  primary      : 'rgb(252, 180, 21)',
  white        : 'rgb(255, 255, 255)',
  grey         : 'rgb(241, 241, 241)',
  black        : 'rgb(0,0,0)',
  darkGrey     : 'rgb(20, 20, 20)',
  greyOnDark   : 'rgb(164, 163, 164)',
  greyOnLight  : 'rgb(76, 82, 97)',
  blueOnDark   : 'rgb(36, 166, 227)',
  blueOnLight  : 'rgb(78, 188, 241)',
  textOnDark   : 'rgb(207, 209, 212)',
  textOnLight  : 'rgb(90, 99,102)'
}

b.fontSizes = {
  title       : '48px',
  uber        : '20px',
  sub         : '30px',
  manchet     : '16px',
  body        : '20px',
  small       : '18px',
  extrasmall  : '6px'
}

const fonts = {
  h1:`
    fs ${ b.fontSizes.title }
    lh calc(1)
    m 0 0 25 0
    c ${ b.colors.greyOnDark }
    fw 600
    ls -1px
  `,
  h2: b`
    fs 36px
    lh calc(1.2)
    m 0 0 25 0
    c ${ b.colors.greyOnDark }
    fw 600
  `.landscape`
    fs ${ b.fontSizes.title }
  `,
  h3: b`
    fs ${ b.fontSizes.uber }
    lh calc(1.2)
    m 0 0 25 0
    tt uppercase
    c ${ b.colors.blueOnDark }
    ls 2
  `.landscape`
    fs ${ b.fontSizes.uber }
  `,
  h4:`
    m 6% 0 3% 0
    c ${ b.colors.blueOnDark }
  `,
  h5:`
    fs ${ b.fs.text }
    font-style italic
    c ${ b.colors.greyOnLight }
  `,
  p: b`
  `
}

b.css({
  ...fonts
})
