
import home from './pages'
import businessMusic from './pages/business_music'
import hardware from './pages/hardware'
import digitalSignage from './pages/digital_signage'
import omOs from './pages/om_os'
import betingelser from './pages/betingelser'
import support from './pages/support'
import layout from './layout'

const routes = (model, actions) => ({
  '/'                : { render: () => layout(model, home), onmatch: actions.navigate },
  '/business-music'  : { render: () => layout(model, businessMusic), onmatch: actions.navigate },
  '/digital-signage' : { render: () => layout(model, digitalSignage), onmatch: actions.navigate },
  '/hardware'        : { render: () => layout(model, hardware), onmatch: actions.navigate },
  '/om'              : { render: () => layout(model, omOs), onmatch: actions.navigate },
  '/betingelser'     : { render: () => layout(model, betingelser), onmatch: actions.navigate },
  '/support'         : { render: () => layout(model, support), onmatch: actions.navigate }
})

export default routes
