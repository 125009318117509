import b from 'bss'
import m from 'mithril'

export default ({
  onclick,
  href
}, children) =>
  m((href ? 'a': 'button') + b`
    m 0 auto
    tt uppercase
    c white
    p 10 35
    border-radius 2px
    background-color ${ b.colors.blueOnDark }
    ls 3px
    font-weight bold
    border none
    fs ${ b.fontSizes.text }
    td none
    `.landscape `
      p 10 50
    `
    .$hover`
      transform translateY(-2px)
    `

  , {
    onclick: (e) => {
      if (onclick)
        return onclick(e)

      if (href && href[0] === '#') {
        e.preventDefault()
        document.querySelector(href).scrollIntoView({ behavior: 'smooth' })
      }
    },
    href,
    oncreate: href && href[0] !== '#' && m.route.link
  }, children)
