import b from 'bss'
import m from 'mithril'
import button from '../../components/button.js'
import hero from '../../components/hero.js'
import infoSection from '../../components/info_section.js'
import Contact from '../../components/contact.js'

export default () => [
  hero({ // Top
    top: true,
    src: '/video/ds_top.mp4',
    backgroundColor: b.colors.black
  },
    m('h1',
      'Digital Signage'
    ),
    m('h2',
      'Udnyt jeres skærme optimalt'
    )
  ),
  infoSection({ id: 'newsletter', reverse: !b.isLandscape() },
    {
      backgroundColor: b.colors.grey,
      small: true,
      content: [
        m('h2',
          'Nyhed: Beat Signage App til Samsung'
        ),
        m('p', `
          Beat Signage App til Samsung skærme - Et værktøj til at håndtere og optimere din digital signage løsning. 
          Med Beat Signage App kan du nemt skræddersy, vedligeholde og overvåge dine skærme i realtid.
          Oplev en ny måde at præsentere dit brand eller produkt med Beat Signage App til Samsung skærme.
        `),
        m('p', `
          Produkt detaljer:
        `),
        m('ul',
          m('li',
          'Spil synkroniseret videoer på tværs af flere signage skærme i realtid'
          ),
          m('li',
            'Planlæg videokampagner på forhånd'
          ),
          m('li',
            'Fjernadgang for din butik for at styre alle signage skærme'
          ),
          m('li',
            'Styring i skyen af flere butikker på én gang'
          ),
          m('li',
            'Indstil tænd/sluk-tider for hver skærm'
          )
        ),
        m('div' + b `text-align: center; margin-top: 50px;`,
          m('a' + b `
            display: inline-block;
            background: #00b6f1;
            color: white;
            padding: 10px;
            text-align: center;
            width: 150px;
            border-radius: 10px;
            margin: 0 20px
          `, {
            href: 'video/signage_app_webremote.mp4',
            target: '_blank'
          },
            'Video Guide'
          ),
          m('a' + b `
            display: inline-block;
            background: #00b6f1;
            color: white;
            padding: 10px;
            text-align: center;
            width: 150px;
            border-radius: 10px;
            margin: 0 20px;
            margin-top: 20px;
          `, { 
            href: 'images/Beat_Signage_App_Showcase.pdf',
            target: '_blank'
          },
            'Nyhedsbrev'
          )
        )
      ]
    },
    {
      backgroundColor: b.colors.white,
      content: m('' + b`
        d grid
        pi center
      `,
        m('a', {
          href: '/images/Beat_Signage_App_Showcase.pdf',
          target: '_blank'
        },
          m('img' + b `
            width 300px
            margin 0 auto
          `, { 
            src: '/images/sam-newsletter.png' 
          }),
        ),
        m('span' + b `
          c ${ b.colors.greyOnLight }
          font-style italic
          ta center
          mt 20px
        `,
          'Samsung skærm med Beat Signage App.'
        )
      )
    }
    ),
  infoSection({},
    {
      backgroundColor: b.colors.white,
      content: [
        m('img', { 
          src: '/images/digital-signage_screen1.jpg' 
        }),
        m('span' + b `
          c ${ b.colors.greyOnLight }
          font-style italic
          ta center
          mt 20px
        `,
          'Skærm med reklame for nyeste kollektion.'
        )
      ]
    },
    {
      backgroundColor: b.colors.grey,
      small: true,
      content: [
        m('h2',
          'In-store skærme & video walls'
        ),
        m('p', `
          Digital signage bruges til at skabe moderne, levende shop design. Vi kan distribuere alle former
          for visuelt materiale såsom video, reklamebilleder, specielle kampagner, alt er i princippet muligt.
          De enkelte filer kan nemt tagges, så indholdet målrettes eksempelvis land, så det er den franske
          udgave af videoen, der vises i butikken i Paris.
        `),
        m('p', `
          Du kan bruge SignagePoint til visning af video, uanset om du har en enkelt lokation eller et globalt brand.
        `),
        m('p', `
          Produktdetaljer:
        `),
        m('ul',
          m('li',
          'Produktvideoer'
          ),
          m('li',
            'Målrettede kampagner'
          ),
          m('li',
            'Sprogversionering'
          ),
          m('li',
            'Schedulering'
          ),
          m('li',
            'Tiled displays'
          ),
          m('li',
            'Video walls'
          )
        )
      ]
    }
  ),
  infoSection({ reverse: !b.isLandscape() },
    {
      backgroundColor: b.colors.grey,
      small: true,
      content: [
        m('h2',
            'Digitale infoboards'
        ),
        m('p', `
            Digitale infoskærme kan bruges til en lang række praktiske formål. Kommuniker internt i virksomheder
            om nyheder og vigtige begivenheder, eller vis målsætninger, statistik og salgstal. Sæt skærme op til
            at styre mødelokaler, og byd virksomhedens gæster velkommen kunder velkommen med relevant info i forhallen.
          `),
        m('p', `
            Kombiner med en news ticker med indhold fra de officielle TV stationer, samt en vejrudsigt eller dagens menu.
          `),
        m('p', `
            Produktdetaljer:
          `),
        m('ul',
            m('li',
            'Velkomstskærme'
            ),
            m('li',
              'Intern kommunikation'
            ),
            m('li',
              'Nyheder'
            ),
            m('li',
              'Dagens menu'
            ),
            m('li',
              'Vejrudsigt'
            ),
            m('li',
              'Statistik og salgstal'
            ),
            m('li',
              'Easy upload'
            )
        )
      ]
    },
    {
      backgroundColor: b.colors.white,
      content: [

        m('img'
          ,
        { src: '/images/digital-signage_screen2.jpg'}),
        m('span' + b `
          c ${ b.colors.greyOnLight }
          font-style italic
          ta center
          mt 20px
        `,
          'Skærm med vejret og nyheder fra TV2 News.'
        )
      ]
    }
  ),

  infoSection({},
    {
      backgroundColor: b.colors.white,
      content: [
        m('img'
          ,
        { src: '/images/digital-signage_screen3.jpg' }),
        m('span' + b `
          c ${ b.colors.greyOnLight }
          font-style italic
          ta center
          mt 20px
        `,
          'Skærm med bilspil kunderne kan spille i butikken med deres mobiler'
        )
      ]
    }, {
      backgroundColor: b.colors.grey,
      small: true,
      content: [
        m('h2',
          'Social & Interactive Signage'
        ),
        m('p', `
          I kraft af stigende krav til kundeoplevelser bliver kravene til systemerne også tilsvarende højere.
          Derfor er der i SignagePoint mulighed for at få lavet apps, der engagerer og aktiverer kunderne.
        `),
        m('p', `
          Via en touch skærm eller en smart phone kan kunderne vælge det indhold de ønsker at se,
          de kan deltage i konkurrencer og spil, tilmelde sig nyhedsbrevet eller foretage selve købet.
        `),
        m('p', `
          Produktdetaljer:
        `),
        m('ul',
          m('li',
          'Interaktiv digital signage'
          ),
          m('li',
            'Konkurrencer'
          ),
          m('li',
            'Customer engagement'
          ),
          m('li',
            'Sign up'
          ),
          m('li',
            'Guide til køb'
          ),
          m('li',
            'Custom apps'
          )
        )
      ]
    }
  ),
  infoSection({},
    {
      content: [
        m(Contact)
      ]
    }, {
      small: true,
      style: 'jc flex-start',
      content: m('div' + b `
      d flex
      fd column
      jc flex-start
      min-height 100%

      `,
      m('h3',
      'Ring til os'
      ),
      m('h1',
        m('a', { href:'tel:89 88 37 00' }, '89 88 37 00')
      ),
      m('h3',
      'Skriv til os'
      ),
      m('h1',
        m('a', { href: 'mailto:info@beat.dk' }, 'info@beat.dk')
      )
      )
    }
  )
]
