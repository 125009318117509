import m from 'mithril'
import b from 'bss'
import footerSvg from '../icons/logo_footer.svg'
import icon from '../components/icon'


const help = [{
  href: 'https://support.beat.dk',
  target: '_blank',
  title: 'Support'
}, {
  href: 'https://status.beat.dk',
  target: '_blank',
  title: 'Status'
}]

const beat = [{
  href: '/om',
  title: 'Om os'
}, {
  href: '/betingelser',
  title: 'Betingelser'
},{
  onclick: e => {
    if (window.newsletter)
      return window.newsletter.scrollIntoView({ behavior: 'smooth' })

    m.route.set('/digital-signage')
    setTimeout(() => window.newsletter.scrollIntoView(), 50)
  },
  title: 'Nyhedsbrev'
}]

const ydelser = [{
  href: '/business-music',
  title: 'Business Music'
}, {
  href: '/digital-signage',
  title: 'Digital Signage'
}, {
  href: '/hardware',
  title: 'Hardware'
}]

export default () =>
  m('footer' + b `
    d flex
    zi 100
    w 100%
    bc ${ b.colors.black }
    fd column
    ta center
    p 40 10
  `.landscape`
    p 5%
    fd row
    ta left
    jc space-between
  `, {
    key: 'footer'
  },
    nav('Beat', beat),
    nav('Services', ydelser),
    // nav('Hjælp', help),
    icon({
      svg: footerSvg,
      styles: b `
        h 100
        mt 24
        mb 30
      `.landscape`
        order 5
      `
    }),
    m('div' + b`
      c ${ b.colors.greyOnDark }
    `.landscape`
     order 4
    `,
      m('p' + b`
        white-space pre-line
      `,
        'Beat A/S'
        ,
        m('br'),
        'Klingseyvej 15B',
        m('br'),
        '2720 Vanløse',
        m('br'),
        m('a', { href: 'tel:+4589883700' }, '+45 89 88 37 00'),
        m('br'),
        m('a', { href: 'mailto://info@beat.dk' }, 'info@beat.dk'),
        m('br'),
        'CVR 25874692',
      )
    )
  )

const nav = (heading, links) =>
  m('nav' + b`
    mb 40
    min-width 50%
  `.landscape`
    min-width auto
  `,
    m('h3', heading),
    links.map(({ href, title, target, onclick }) =>
      m('a' + b`¨
        display block
        lh calc(1.8)
        c white
        td none
        fs 18
      `.$hover`
        td underline
        c white
      `, {
        selected: location.pathname === href,
        href,
        target,
        onclick,
        oncreate: !onclick && !target && m.route.link
      }, title)
    )
  )
