import b from 'bss'
import m from 'mithril'

export default ({
  white = false,
  position = 'right',
  top = false,
  src,
  alt,
  srcLandscape = src,
  backgroundColor = '#242424',
  video = src.match(/\.mp4$/)
}, ...children) =>

  m('section.hero' + b`
    position relative
    background-color ${backgroundColor}
    w 100%
    display flex
    flex-direction row
    justify-content flex-end
    overflow hidden
  `.landscape`
    h 58vw
    max-height 95vh
    ${ position === 'left' && `
      justify-content flex-start
    `}
  `.$nest('p', b `
    w 100%
    c ${ white ? b.colors.textOnLight : b.colors.textOnDark }
  `.landscape`
    w ${ position === 'right' ? '80%' : '70%' }
  `).$nest('h1', b`
    fs 12vw
    lh calc(1.5)
    c ${ b.colors.white }
    m 0
  `.landscape`
      fs 6vw
  `).$nest('h1 + h2', b`
    fs 6.5vw
    p 0 10%
    fw normal
  `.landscape`
    fs 2.5vw
  `).$nest('h3', `
    c ${ white ? b.colors.blueOnLight : b.colors.blueOnDark })
  `).$nest('h2', `
    c ${ white ? b.colors.greyOnLight : b.colors.greyOnDark })
  `),
    m((video ? 'video' : 'img') + b`
      position absolute
      top 0
      left 0
      w ${ video ? '270%' : '100%' }
      ml ${ video ? '-55%v' : '0' }
      object-fit cover
      o 0
      transition opacity 1s
      `.landscape`
        width 100%
        ml 0
        min-width 100%
        min-height 100%
    `, {
      src: b.isLandscape() ? srcLandscape : src,
      alt,

      // Workaround for mithril not setting muted properly in safari using attrs
      oncreate: video && (({ dom }) => {
        dom.setAttribute('muted', '')
        dom.setAttribute('playsinline', '')
      }),

      onload: !video && ((e) => e.target.style.opacity = 1),
      ...(video ? {
        onloadedmetadata: (e) => e.target.style.opacity = 1,
        loop: true,
        muted: true,
        playsinline: true,
        autoplay: true
      } : {})
    }),

    m('div' + b`
      w 100%
      display flex
      justify-content flex-end
      flex-direction column
      p 0 5% 5% 5%
      margin-top 100vw
      z-index 1
      ${ top && `
        ta center
      `}
    `.landscape`
      margin-top 0
      w ${ top ? '55%' : '50%' }
      ${ position === 'left' && `
        p 0 0 5% 5%
      `}
    `,
      children
    )
  )

