import b from 'bss'
import m from 'mithril'
import Contact from '../../components/contact.js'
import hero from '../../components/hero.js'
import infoSection from '../../components/info_section.js'
import icon from '../../components/icon.js'
import stopWatchSvg from '../../icons/stop-watch.svg'
import headphonesSvg from '../../icons/headphones.svg'
import samvittighedSvg from '../../icons/samvittighed.svg'
import shieldSvg from '../../icons/shield.svg'

export default () => [

  hero({ // Top
    name: 'hero1',
    top: true,
    src: '/images/business-music-mobile.jpg',
    srcLandscape: '/images/business_music.jpg',
    backgroundColor: '#0d1c0a'
  },
    m('h1', 'Business Music'),
    m('h2', 'Musik for kunder og gæster')
  ),

  infoSection({ // Udvalgt af eksperter
  },
    {
      backgroundColor: b.colors.white,
      content: [
        m('h2',
          'Vores musikeksperter er klar'
        ),
        m('p', `
          Vores musikeksperter har mange års erfaring i at udvælge den rigtige musik. Det resulterer
          i en unik musikprofil, der understøtter brandets udtryk og identitet.
        `),
        m('p', `
          Beat har skabt musikprofiler til nogle af de største detailkæder med butikker i hele verden
          — Vi kan hjælpe dit brand til at få den helt rigtige lyd, der inkorporerer jeres brands DNA.
        `)
      ]
    },
    {
      backgroundColor: b.colors.grey,
      content: [

        icon({
          svg: headphonesSvg
        })

      ]
    },
  ),
  infoSection({ // Udvalgt af eksperter
    reverse: !b.isLandscape()
  },
    {
      backgroundColor: b.colors.grey,
      content: [
        icon({
          svg: shieldSvg
        })
      ]
    },
    {
      backgroundColor: b.colors.white,
      content: [
        m('h2',
            'Pålidelig afspilning'
        ),
        m('p', `
          Med vores mange års erfaring i branchen ved vi alt om, hvor vigtigt det er, at der aldrig
          er udfald i musikken. Med vores medieafspiller sikrer du pålidelig afspilning, også når
          enheden ikke er online.
        `),
        m('p', `
          Vores system sikrer også at dine playlister spiller på det tidspunkt, du har ønsket, og
          hvis du får lyst til at vælge en ny sang, sker dette elegant med crossfade.
        `)
      ]
    },
  ),

  infoSection(
    {
      angle: -5
    },
    {
      backgroundColor: b.colors.white,
      content: [
        m('h2',
          'Den rette timing'
        ),
        m('p', `
          Med vores unikke 'Music Timer', kan du planlægge musikken lang tid i forvejen.
        `),
        m('p', `
          Beat har skabt musikprofiler til nogle af de største detailkæder med butikker i hele verden
          — Vi kan hjælpe dit brand til at få den helt rigtige lyd, der inkorporerer jeres DNA og identitet.
        `)
      ]
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        icon({
          svg: stopWatchSvg,
          styles: b`
            height 240
          `
        })
      ]
    }
  ),
  infoSection({ // Udvalgt af eksperter
    reverse: !b.isLandscape()
  },
    {
      backgroundColor: b.colors.grey,
      content: [
        icon({
          svg: samvittighedSvg,
          styles: b`
            height 150
            `
        })
      ]
    },
    {
      backgroundColor: b.colors.white,
      content: [
        m('h2',
            'Med god samvittighed'
        ),
        m('p', `
          Har du styr på din musik? — Vi har styr på rettighederne og sørger for den korrekte
          rapportering.
        `),
        m('p', `
          Det eneste du skal tænke på er indberetning til Koda ved fremførsel af musik offentligt
          for dine kunder og gæster.
        `)
      ]
    },
  ),

  infoSection(
    {},
    {
      content: [
        m(Contact)
      ]
    }, {
      small: true,
      style: 'jc flex-start',
      content: m('div' + b `
      d flex
      fd column
      jc flex-start
      min-height 100%

      `,
      m('h3',
      'Ring til os'
      ),
      m('h1',
        m('a', { href:'tel:89 88 37 00' }, '89 88 37 00')
      ),
      m('h3',
      'Skriv til os'
      ),
      m('h1',
        m('a', { href: 'mailto:info@beat.dk' }, 'info@beat.dk')
      )
      )
    })
]



