import b from 'bss'
import m from 'mithril'

const container = ({
  theme,
  small,
  titleColor,
  style
}) => b`
  position relative
  d flex
  fd column
  jc center
  ai center
  fg 1
  w 100%
  p 20% 8%
  c ${ b.colors.greyOnLight }
`.landscape`
  ${ small && 'max-width 40%' }
  p 8%
`.$nest('h2', b `
  mb 50px
  c ${ b.colors.greyOnLight }
`).$nest('img', `
    max-width 100%
    min-height 1
  `)
+ b(style)

export default ({
  angle = 5,
  reverse = false,
  id
}, first, second) =>
  m('section' + b`
    position relative
    overflow hidden
    d flex
    w 100%
    bc ${ second.backgroundColor }
    fd ${ reverse ? 'column-reverse' : 'column' }
  `.landscape`
    fd row
  `
  .$nest('.icon', `
    h 250
    m 5% auto
  `), { id },
    m('.first' + container(first),
      m('div' + b`
        position relative
        z-index 1
        d flex
        fd column
        max-width 680
      `
      ,
        first.content
      ),
      m('div' + b`
        position absolute
        t ${ reverse ? 0 : -20}%
        w 140%
        l -20%
        h 120%
        bc ${ first.backgroundColor }
        transform-origin center center
        transform rotate(${ angle }deg)
      `.landscape`
        transform-origin right center
        t -25%
        l ${ angle <= 0 ? '-15%' : '-10%'}
        w 110%
        h 150%
      `)
    ),

    m('.second' + container(second),
      m('div' + b`
        max-width 680
        d flex
        fd column
      `,
        second.content
      )
    )
  )
