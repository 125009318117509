import b from 'bss'
import m from 'mithril'
import button from '../components/button.js'
import hero from '../components/hero.js'
import infoSection from '../components/info_section.js'
import Contact from '../components/contact.js'
import icon from '../components/icon.js'
import logoSvg from '../icons/logo-only.svg'

export default () => [

  false && hero({ // Top
    name: 'hero1',
    top: true,
    src: '/images/beat-player-top_portrait.jpg',
    alt: 'Business Music & Digital Signage',
    srcLandscape: '/images/beat-player-top.jpg',
    backgroundColor: '#121212'
  }
    ,
    m('h1' + b`fs 5vw;mb 5vh;lh calc(1.2)`, 'Business Music & Digital Signage'),
    m('h2', 'Leverandør af musik- ', m('br'), 'og videoløsninger')
  ),

  hero({ // Top
    name: 'hero1',
    top: true,
    src: '/images/beat-player-top_portrait.jpg',
    alt: 'Business Music & Digital Signage',
    srcLandscape: '/images/beat-player-top.jpg',
    backgroundColor: '#1c1c1c'
  }
    ,
    m('h1', 'Musik & video'),
    m('h2', 'Leverandør af Business Music og Digital Signage')
  ),

  hero({ // Digital Signage
    src: '/images/digital-signage_right_portrait.jpg',
    alt: 'Digital Signage',
    srcLandscape: '/images/digital-signage_right.jpg',
    backgroundColor: '#0d0d0b',
    position: 'left'
  },
    m('h3', 'Digital Signage'),
    m('h2', 'Udnyt jeres skærme optimalt'),
    m('p', `
      Retailbutikker, hoteller eller storcentre — Planlæg og administrer
      alt indhold, så det afvikles gnidningsfrit, og som I ønsker.
    `),
    m('p', `
      Tilpas indhold til tidspunkt på dagen, særlige begivenheder som udsalg og Black Friday.
    `),
    m('p' + b`
      p 16 5%
      ta center
    `,
      button({
        href: '/digital-signage'
      },
        'Læs mere'
      )
    )
  ),

  hero({ // Business Music
    src: '/images/business-music-mobile.jpg',
    alt: 'Business Music',
    srcLandscape: '/images/business_music.jpg',
    backgroundColor: '#0d1c0a'
  },
    m('h3', 'Business Music'),
    m('h2', 'Musik for kunder & gæster'),
    m('p', `Har du styr på din musik? —
      Vi har styr på rettighederne og sørger for den korrekte rapportering. Det eneste du skal tænke
      på er indberetning til Koda ved fremførsel af musik offentligt.
    `),
    m('p', `
      Med vores løsning kan du spille musik med god samvittighed for dine kunder og gæster.
    `),
    m('p' + b`
      p 16 5%
      ta center
    `,
      button({
        href: '/business-music'
      },
        'Læs mere'
      )
    )
  ),

  infoSection({ // Beat
    angle: 5
  },
  {
    backgroundColor: b.colors.darkGrey,
    content: [
      m('h3', 'Business music & Digital signage'),
      m('h2' + b `
        c ${ b.colors.greyOnDark }
        `,
          'Velkommen hos Beat'),
      m('p' + b `c white`, `
        Beat er markedets førende leverandør af professionelle løsninger
        inden for Business Music og Digital Signage — vi er eksperter i
        at skabe den helt rigtige atmosfære.
      `),

      m('p' + b `c white`, `
        Vores løsninger bruges i de største danske detailkæder med over 5000
        butikker i hele verden, og vi har leveret musik til bar- og cafélivet
        i Danmark gennem de sidste 20 år.
      `)
    ]
  },
  {
    backgroundColor: b.colors.black,
    content: [
      m('div' + b `
        d flex
        ai center
        flex-wrap wrap
      `,
        icon({
          svg: logoSvg
        })
      )
    ]
  }),

  hero({ // Beat Player
    position: 'left',
    src: '/images/beat-player_portrait.jpg',
    alt: 'Beat Player',
    srcLandscape: '/images/beat-player.jpg',
    backgroundColor: '#181818'
  },
    m('h3', 'Beat Player'),
    m('h2', 'Musikstreaming'),
    m('p', `
      Bar, Café eller Restaurant – Vælg blandt millioner af sange direkte
      på stedet eller planlæg spillelister nøje fra hjemmet eller kontoret.
    `),
    m('p', `
      Tilpas musikken så den altid spiller og passer til de forskellige tidspunkter
      på dagen. I kan til enhver tid gribe stemningen og sætte præcist det nummer på,
      som gæsterne gerne vil høre.
    `)
  ),

  hero({ // Brand identity
    src: '/images/brand_music_mobile.jpg',
    alt: 'Brand identity',
    srcLandscape: '/images/brand_music.jpg',
    backgroundColor: '#000000'
  },
    m('h3', 'Brand identity'),
    m('h2', 'De helt rigtige tracks'),
    m('p', `
      Vores musikeksperter har mange års erfaring i at udvælge den rigtige musik. Det resulterer i
      en unik musikprofil, der understøtter brandets udtryk og identitet.
    `),
    m('p', `
      Beat har skabt musikprofiler til nogle af de største detailkæder med butikker i hele verden
      — Vi kan hjælpe dit brand til at få den helt rigtige lyd, der inkorporerer jeres brands DNA.
    `)
  ),

  hero({ // MIB / Point
    src: '/images/mib-front_portrait.jpg',
    alt: 'Advanced Point Technology',
    srcLandscape: '/images/mib-front.jpg',
    backgroundColor: '#030303',
    position: 'left'
  },
    m('h3', 'Advanced Point Technology'),
    m('h2', 'Pålidelig afspilning'),
    m('p', `
      Alt indhold styres centralt, og bliver med vores Advanced Point Technology automatisk
      distribueret til lydsystemer og skærme.
    `),
    m('p', `
      Tilslut vores klassiske MIB, der er nem at installere, og som garanterer en stabil afspilning
      af alle dine mediefiler — også når den ikke er online.
    `)
  ),

  hero({ // Hardware / Support
    src: '/images/hardware_portrait.jpg',
    alt: 'Hardware',
    srcLandscape: '/images/hardware.jpg',
    backgroundColor: '#0e0e0e'
  },
    m('h3', 'Fra rådgivning til installation'),
    m('h2', 'Komplette løsninger'),
    m('p', `
      Vi kan skræddersy jeres løsning med det helt rigtige lydanlæg og skærmsetup.
      Vi har 20 års erfaring i at rådgive og finde den helt rigtige løsning for vores kunder i hele verden.
    `),
    m('p', `
      Beat er pro-leverandør af blandt andet Bose, Samsung, LG og Bosch.
      Vi producerer egen hardware i form af lydanlæg og medieafspillere.
      Vi kan tilbyde professionel installation og kundesupport årets 365 dage — Beat er med dig hele vejen.
    `)
  ),

  infoSection(
    {},
    {
      content: [
        m(Contact)
      ]
    },
    {
      small: true,
      style: 'jc flex-start',
      content: m('div' + b `
        d flex
        fd column
        jc flex-start
        min-height 100%
      `,
        m('h3',
        'Ring til os'
        ),
        m('h1',
        m('a', { href:'tel:89 88 37 00' }, '89 88 37 00')
        ),
        m('h3',
        'Skriv til os'
        ),
        m('h1',
          m('a', { href: 'mailto:info@beat.dk' }, 'info@beat.dk')
        )
      )
    })
]
