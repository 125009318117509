import m from 'mithril'
import b from 'bss'

export default ({
  svg,
  styles={},
  ...attrs
}) =>
  m('div.icon' + b`
    height 24
  `.$nest('svg', `
    height 100%
  `), {
    class: styles.class,
    ...attrs
  }, m.trust(svg))
