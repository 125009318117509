import b from 'bss'
import m from 'mithril'
import infoSection from '../../components/info_section.js'
import contact from '../../components/contact.js'
import icon from '../../components/icon.js'
import logoSvg from '../../icons/logo-only.svg'

export default () => [

infoSection({ // Beat
    angle: 5
  },
  {
    backgroundColor: b.colors.darkGrey,
    content: [

      m('p' + b`
        white-space pre-line
        c ${ b.colors.greyOnDark }
        fs 48
      `,
        'Beat A/S'
        ,
        m('br'),
        'Klingseyvej 15B',
        m('br'),
        '2720 Vanløse',
        m('br'),
        m('a', { href: 'tel:+4589883700' }, '+45 89 88 37 00'),
        m('br'),
        m('a', { href: 'mailto://info@beat.dk' }, 'info@beat.dk'),
        m('br'),
        'CVR 25874692',
      )
    ]
  },
  {
    backgroundColor: b.colors.black,
    content: [
      m('div' + b `
        d flex
        ai center
        flex-wrap wrap
      `,
        icon({
          svg: logoSvg
        })
      )
    ]
  }),
  infoSection(
    {},
    {
      content: [
        contact({})
      ]
    },
    {
      small: true,
      style: 'jc flex-start',
      content: m('div' + b `
        d flex
        fd column
        jc flex-start
        min-height 100%
      `,
        m('h3',
        'Ring til os'
        ),
        m('h1',
        m('a', { href:'tel:89 88 37 00' }, '89 88 37 00')
        ),
        m('h3',
        'Skriv til os'
        ),
        m('h1',
          m('a', { href: 'mailto:info@beat.dk' }, 'info@beat.dk')
        )
      )
    })
]
