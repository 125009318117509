import m from 'mithril'
import b from 'bss'

import header from './header.js'
import footer from './footer.js'

export default (model, page) =>
  m('.layout',

    header(),

    m('main' + b`
      position relative
      min-height 100vh
      top 0
      left 0
      zi 1
      w 100%
      color white
      transition transform 0.5s, opacity 0.5s
    `.$animate('0.5s', {
      from: b`
        o 0
        transform translateX(${ model.direction * 100 }vw)
      `
    }), {
      key: model.path,
      onbeforeremove: ({ dom }) => new Promise(resolve => {
        dom.style.position = 'absolute'
        dom.style.opacity = 0
        const oldScrollTop = document.documentElement.scrollTop || document.body.scrollTop
        dom.style.top = -oldScrollTop + 'px'
        window.scrollTo(0, 0)
        dom.style.transform = `translateX(${ -model.direction * 100 }vw)`
        setTimeout(resolve, 500)
      })
    },
      page()
    ),

    footer()

  )
