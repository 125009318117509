import b from 'bss'
import m from 'mithril'

export default ({
  name,
  label,
  type,
  placeholder,
  required,
  input
}) => {
  const inputFilled = input[name] && input[name].length > 0

  return m('div' + b`
    c ${ b.colors.greyOnDark }
    w 100%
    mb 28
    position relative
  `,

    m('input' + b`
      fs ${ b.fontSizes.small }
      background none
      border 1px solid ${ b.colors.blueOnDark }
      p 22 10 10 10
      w 100%
      h 58
      c white
      `.$focus(`
        bw 2px
      `).$nest(':focus +label', `
        fs 12
        mt 5
      `), {
      id: name,
      type,
      placeholder,
      name,
      required,
      value: input[name],

      oninput: e => input[name] = e.target.value
    }),

    m('label' + b`
      fs ${inputFilled > 0 ? 12 : b.fontSizes.small}
      display block
      position absolute
      mt ${inputFilled > 0 ? 5 : 18}
      t 0
      l 11
      ls 2px
      cursor text
      transition all .2s
    `, {
      for: name
    },
      label
    )
  )
}
