import m from 'mithril'
import b from 'bss'

import input from './input'
import textArea from './textarea'
import button from './button'

export default () => {
  let state = 'idle'
    , timeout

  const formData = {
    name: '',
    email: '',
    message: '',
    phone: ''
  }

  return {
    onremove: () => clearTimeout(timeout),

    view: () =>
      m('#contact' + b`
        w 100%
      `,
        m('h3', 'Vi er klar til at hjælpe' ),
        m('h2' + b`
          c ${ b.colors.greyOnDark }
          m 0
        `,

          'Kontakt os'
        ),

        m('h3#thankyou' + b`
          opacity ${state === 'sent' ? '1' : '0'}
          transition all .5s
          maxHeight ${state === 'sent' ? '24px' : '0'}
          overflow hidden
          mt ${state === 'sent' ? '25px' : '0'}
        `,

          'Tak for din besked.'
        ),

        m('form' + b`
          opacity ${state === 'sent' ? '0' : '1'}
          d flex
          flexWrap wrap
          jc space-between
          transition all .5s
        `,

          {
            action: 'api',
            method: 'post',

            onsubmit: e => {
              e.preventDefault()

              if (state === 'sending')
                return

              state = 'sending'

              m.request({
                method: 'POST',
                url: '/.netlify/functions/sendMail',
                data: formData
              })
              .then(() => {
                state = 'sent'
                formData.name = formData.email = formData.message = formData.phone = ''
                timeout = setTimeout(() => {
                  state = 'idle'
                  m.redraw()
                }, 10000)
              })
              .catch(() => {
                state = 'error'
              })
            }
          },

          input({
            name: 'name',
            label: 'Navn',
            type: 'text',
            required: true,
            input: formData
          }),

          input({
            name: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            input: formData
          }),

          input({
            name: 'phone',
            label: 'Telefon',
            type: 'phone',
            required: false,
            input: formData
          }),

          textArea({
            name: 'message',
            label: 'Besked',
            required: true,
            input: formData
          })
          ,

          m('p' + b`
            color white
            opacity ${state === 'error' ? '1' : '0'}
            transition all .5s
            maxHeight ${state === 'error' ? '24px' : '0'}
            overflow hidden
            mt ${state === 'error' ? '0' : '-30'}
          `,
            'Beskeden kunne desværre ikke sendes, prøv igen eller ring til os.'
          ),

          button({},
            state === 'sending' ? 'sender...' : 'send'
          )
        )
      )
  }
}
