import b from 'bss'
import m from 'mithril'
import hero from '../../components/hero.js'
import infoSection from '../../components/info_section.js'
import Contact from '../../components/contact.js'

export default () => [

  hero({
    name: 'hero1',
    top: true,
    src: '/images/mib-mobile.jpg',
    srcLandscape: '/images/mib.jpg',
    backgroundColor: '#202020'
  }
  ,
    m('h1', 'Hardware'),
    m( 'h2', 'Med det rette udstyr sikrer I den bedste kundeoplevelse')
  ),
  infoSection(
    {},
    {
      backgroundColor: b.colors.white,
      theme: 'white',
      small: true,
      content:
      [
        m('h2',
          'Medieafspiller'
        ),
        m('p', `
          MIB er den medieafspiller, som bruges til at afvikle jeres medieindhold. Med vores Advanced
          Point Technology får I fuld adgang til alt det medieindhold, som jeres MIB skal spille.
          Vi sætter de overordnede indstillinger på forhånd, og så bliver musik og visuelt indhold
          automatisk distribueret ud til lydsystem og skærme.
        `),
        m('p', `
          Vores MIB har et lækkert design og er nem at installere. Den passer ind i ethvert set-up,
          uanset om den skal stå i baglokalet, ved disken eller i baren.
        `)
      ]
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        m('img',
      { src: '/images/mib.png' })
      ]
    }
  ),
  infoSection(
    {
      reverse: !b.isLandscape()
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        m('img',
          { src: '/images/screen.png' }
        )
      ]
    },
    {
      backgroundColor: b.colors.white,
      theme: 'white',
      small: true,
      content: [
        m('h2',
          'Skærme'
        ),
        m('p', `
          Beat leverer professionelle skærme, der er kendetegnet ved højere kontrast, lang levetid,
          og at de er bygget til at stå tændt i mange timer. Vi finder den model, der lever op til
          jeres behov, afhængigt af medieindhold, skærmstørrelse og placering. Skal skærmen
          eksempelvis hænge alene, indgå i en video wall, eller skal den bruges i direkte sollys?
        `),
        m('p', `
          Når I køber skærmene hos os, er I sikre på at få det rette produkt til visning af jeres
          digital signage-indhold, og I en travl hverdag er det rart at vide, at I kun skal henvende
          jer til en leverandør for support og vejledning på skærmløsningen.
        `)
      ]
    }
  ),
  infoSection(
    {},
    {
      backgroundColor: b.colors.white,
      theme: 'white',
      small: true,
      content: [
        m('h2',
        'Højttalere'
        ),
        m('p', `
          Beat leverer kvalitetshøjttalere fra en række førende mærker, herunder Bose, Sonance,
          Evid og Cornered Audio. Alle vores højttalere er udviklet til professionel brug, og vi
          hjælper jer med at finde de modeller, der passer til jeres behov. Skal de eksempelvis
          indbygges, monteres på væggen, være pendel-højtalere, der hænger fra loftet, og skal det
          være til indendørs eller udendørs brug?
        `),
        m('p', `
          For at finde ud af hvilken løsning, I har brug for, kommer vi gerne på besøg, så vi kan se
          lokalernes indretning og akustik og lave en plan for, hvordan vi kan skabe den optimale
          lyd. Ring til os og hør nærmere.
        `)
      ]
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        m('img',
          { src: '/images/loudspeaker.png' }
        )
      ]
    }
  ),
  infoSection(
    {
      reverse: !b.isLandscape()
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        m('img',
          { src: '/images/touch-screen.png'}
        )
      ]
    },
    {
      backgroundColor: b.colors.white,
      theme: 'white',
      small: true,
      content: [
        m('h2',
          'Touch-skærme'
        ),
        m('p', `
          Professionel 15″ skærm med multi-touch funktion, der er bygget til at stå tændt i mange
          timer. Skærmen er udviklet specielt til butik, bar og restaurationsmiljø, da den kan tåle
          stænk og er let at rengøre.
        `),
        m('p', `
          Denne skærm er perfekt til vores Beat Player, når personalet selv skal vælge musikken bag baren
          eller ved disken.
        `)
      ]
    }
  ),
  infoSection(
    {},
    {
      backgroundColor: b.colors.white,
      small: true,
      theme: 'white',
      content: [
        m('h2',
          'Forstærkere'
        ),
        m('p', `
          Beat leverer kvalitetsforstærkere fra en række førende mærker, herunder Bose, JBL, Apart
          Audio og Ecler, samt vores egen Beat forstærker. Alle vores forstærkere er udviklet til
          professionel brug, hvilket giver høj lydkvalitet og lang holdbarhed. Vi hjælper jer med at
          finde en model, der passer perfekt til jeres behov. Skal der være forskellige zoner med
          hver deres volumenkontrol, eller skal det være et mere enkelt setup, hvor I med fordel kan
          bruge vores brugervenlige Beat-forstærker?
       `),
        m('p', `
          Hvis I er interesserede i en samlet løsning bestående af forstærker og højttalere, kommer
          vi gerne på besøg, så vi kan lave en plan for, hvordan vi kan skabe den optimale lyd. Ring
          til os og hør nærmere.
        `)
      ]
    },
    {
      backgroundColor: b.colors.grey,
      content: [
        m('img',
          { src: '/images/amp.png' }
        )
      ]
    }
  ),
  infoSection(
    {},
    {
      content: [
        m(Contact)
      ]
    }, {
      small: true,
      style: 'jc flex-start',
      content: m('div' + b `
      d flex
      fd column
      jc flex-start
      min-height 100%

      `,
      m('h3',
      'Ring til os'
      ),
      m('h1',
        m('a', { href:'tel:89 88 37 00' } ,'89 88 37 00')
      ),
      m('h3',
      'Skriv til os'
      ),
      m('h1',
        m('a', { href: 'mailto:info@beat.dk' }, 'info@beat.dk')
      )
      )
    })

]
