import b from 'bss'
import m from 'mithril'

export default () =>

  m('div' + b `
    background-image url( './images/background.jpg' )
    background-size contain
    background-repeat no-repeat
    w 100%
    m 0 auto
    p 100 4%
    `.landscape `
        p 10% 20%
    `
  ,
    m('h3',
        'SALGS- OG LEVERINGSBETINGELSER'
    ),
    m('h1',
    'Handelsbetingelser'
    ),
    m('h4',
        '1. Anvendelse'
    ),
        m('p',
        `
        Nedenstående handelsbetingelser (herefter benævnt ”Vilkårene”) gælder for salg, leje og/eller
        levering af enhver ydelse/produkt (herefter benævnt ”Varen” eller ”Ydelsen”) fra BEAT A/S
        (herefter benævnt BEAT) til erhvervsdrivende og organisationer i Danmark (herefter benævnt
        ”Kunden”).
        `
        ),
        m('p',
        `
        Vilkårene gælder medmindre andet udtrykkeligt og skriftligt er aftalt mellem Kunden og BEAT,
        og uanset om aftalen er indgået via, internet, e-shop, e-mail, telefax, telefon eller på
        anden vis.
        `
        ),
        m('p',
        `
        Hvis en aftale omfatter hardware eller software, kan disse desuden være omfattet af særlige
        licens- og garantivilkår udstedt af tredjepartsleverandøren, hvortil der henvises. I sådanne
        tilfælde er der tale om en aftale indgået direkte mellem Kunden og tredjepartsleverandøren
        uden noget ansvar for BEAT.
        `
        ),
        m('p',
        `
        Det er en forudsætning for Kundens køb, at disse Vilkår accepteres og Kunden opfordres til
        at læse Vilkårene grundigt igennem.
        `),

    m('h4',
        '2. Oplysninger, aftaleindgåelse mv.'
    ),
        m('p',
        `
        BEAT A/S – Klingseyvej 15B – 2720 Vanløse. CVR-nr.: 25874692.
        For mere information se hjemmesiden: www.beat.dk eller ret henvendelse til info@beat.dk.
        `
        ),
        m('p',
        `
        Enhver produktinformation samt enhver oplysning om tekniske data og funktionalitet (f.eks.
        ydeevne, oppetid, svartid mv.) er kun vejledende. Kunden har det fulde ansvar for udvælgelse
        af Varen/Ydelsen herunder at Varen/Ydelsen kan fungere i Kundens påtænkte driftsmiljø.
        Kundens afgivelse af ordre forudsætter oprettelse som Kunde i BEATs Kunderegister.
        `
        ),
        m('p',
        `
        Såfremt Kunden efterfølgende skifter adresse, skal Kunden oplyse dette ved at logge ind på
        www.beat.dk og ændre oplysninger her.
        `
        ),
        m('p',
        `
        Ethvert udbud af Varer/Ydelser, prisoplysninger mv. på BEATs hjemmeside samt i salgsmateriale,
        kataloger mv. er alene at opfatte som en opfordring til at gøre tilbud, og er derfor ikke
        bindende for BEAT.
        `
        ),
        m('p',
        `
        Endelig aftale mellem Kunden og BEAT anses først for indgået, når BEAT efter at have modtaget
        Kundens ordre på sin server, har sendt en skriftlig ordrebekræftelse pr. e-mail med oplysninger
        om ordrenummer, navn og adresse, betalingsmåde, leveringsadresse samt en oversigt over de
        bestilte Varer/Ydelser. Ved afgivelse en varebestilling via BEATs hjemmeside, vil systemet
        automatisk genere en modtagelsesbekræftelse, der afsendes via e-mail. Dette automatiske svar
        (via e-mail) er IKKE en bindende ordrebekræftelse, hvorved en aftale er indgået. Der er alene
        tale om en elektronisk kvittering for modtagelse af ordren, og BEAT kan frit annullere ordren,
        indtil en endelig ordrebekræftelse er afsendt.
        `
        ),
        m('p',
        `
        Kunden opfordres til at udskrive ordrebekræftelsen umiddelbart efter modtagelsen, da denne
        kan være relevant i forbindelse med returnering eller reklamation. En papirbaseret faktura
        sendes sammen med Varen/Ydelsen, såfremt der ikke er truffet aftale om elektronisk fakturering,
        hvor fakturaen fremsendes i forbindelse med afsendelse af Varen/Ydelsen.
        `
        ),


    m('h4',
        '3. Levering og leveringstid'
    ),
        m('p',
        `
        Levering sker udelukkende til adresser i Danmark, Færøerne og Grønland. BEAT leverer ikke til
        udenlandske modtageradresser.
        `
        ),
        m('p',
        `
        Levering sker CIP (INCOTERMS 2000). Dette indebærer, at BEAT sørger for forsendelsen og at
        forsikre Varen under transporten.
        `
        ),
        m('p',
        `
        Leveringstiden er generelt indenfor 7 arbejdsdage dog afhængig af ordrens størrelse,
        beskaffenhed og hvad der i øvrigt er aftalt mellem Kunden og BEAT. Det leveringstidspunkt som
        oplyses af BEAT er omtrentlig og dermed uforpligtende for BEAT, medmindre der udtrykkeligt er
        aftalt fast leveringstid for hele ordren eller dele heraf.
        `
        ),
        m('p',
        `
        Kan levering som følge af Kundens forhold ikke gennemføres, henstår Varen for Kundens regning
        og risiko. BEAT er i denne anledning berettiget til at opkræve lagerleje, omkostninger m.v.
        `
        ),
        m('p',
        `
        I tilfælde af forsinkelse kan Kunden alene ophæve aftalen og kun, hvis forsinkelsen overstiger
        3 uger. Kunden har i så fald alene ret til at få tilbagebetalt allerede erlagt vederlag, og
        Kunden kan således ikke gøre andre misligholdelsesbeføjelser gældende i anledning af den
        opståede forsinkelse.
        `
        ),
        m('p',
        `
        Hvis aftalen med Kunden omfatter flere Varer/Ydelser, og der foreligger forsinkelser eller
        mangler ved en del af leverancen, er Kunden desuagtet forpligtet til at betale for de
        Varer/Ydelser, der er leveret rettidigt og/eller uden mangler. Har Kunden efter disse Vilkår
        ret til at ophæve aftalen, gælder denne ophævelsesadgang alene den forsinkede eller manglefulde
        del af leverancen.
        `
        ),

    m('h4',
        '4. Priser, fragtomkostninger og betaling'
    ),

    m('h4',
        '4.1 Priser'
    ),
        m('p',
        `
        Alle priser er ekskl. moms, afgifter og fragtomkostninger mv. Der tages forbehold for
        prisændringer, der kan forekomme helt frem til levering. Forhøjes prisen på en Vare/Ydelse
        med mere end [10 %] i opadgående retning mellem aftalens indgåelse og levering, kan Kunden
        erstatningsfrit træde tilbage fra aftalen, hvis dette meddeles BEAT skriftligt øjeblikkeligt
        efter, at Kunden er blevet bekendt med ændringen af prisen.
        `
        ),


    m('h4',
        '4.2 Fragtomkostninger'
    ),
        m('p',
        `
        BEAT anvender, medmindre andet fremgår af ordrebekræftelsen, Postdanmark til levering i Danmark.
        Fragtomkostningerne beregnes i overensstemmelse med de til enhver tid gældende vilkår hos Postdanmark.
        `),

    m('h4',
        '4.3 Betaling'
    ),
        m('p',
        `
        Betaling for ordrer optil DKK 20.000 kan ske med følgende betalingskort: Dankort og Visa/Dankort.
        BEAT anvender en betalings-gateway, der garanterer, at alle kortdata sendes krypteret, og alle
        afgivne kortoplysninger er sikrede. Kunden kan ikke foretage modregning i købesummen for krav
        hidrørende fra andre retsforhold, og Kunden kan ikke udøve tilbageholdsret eller nægte betaling
        på grund af forsinkelse, reklamation eller modkrav vedrørende den konkrete leverance.
        `
        ),

    m('h4',
        '4.4 Forsinket betaling'
    ),
        m('p',
        `
        Ved forsinket betaling er BEAT berettiget til at opkræve morarenter af det skyldige beløb med
        2 % pr. påbegyndt måned, fra betalingsfristens udløb og indtil betalingen sker. Ved fremsendelse
        af rykkere opkræves et rykkergebyr på DKK 100. BEAT udsender tre rykkerskrivelser, før kravet
        tages til inkasso.
        `
        ),
        m('p',
        `
        Ved manglende betaling er BEAT berettiget til at forhindre brug af Varen/Ydelsen.
        `
        ),

    m('h4',
        '5. Ejendomsforbehold'
    ),
        m('p',
        `
        BEAT forbeholder sig ejendomsretten til Varen, så længe der ikke er modtaget fuld betaling
        for den bestilte Vare.
        `
        ),
        m('p',
        `
        ndtil ejendomsretten er overgået til Kunden, skal det solgte opbevares og vedligeholdes
        forsvarligt. Skader på det solgte, udover hvad sædvanligt slid medfører, skal erstattes af
        Kunden. Kunden har desuden pligt til at holde det solgte brand-, tyveri- og vandskadeforsikret
        til den fulde værdi. Kunden forpligter sig til ikke uden BEATs skriftlige samtykke at disponere
        over det solgte på en måde, der kan forringe BEATs sikkerhed i Varen, herunder men ikke
        begrænset til pantsætning, udlejning eller udlån af Varen.
        `
        ),


    m('h4',
        '6. Returret'
    ),
        m('p',
        `
        Kunden har returret indtil 8 dage efter leveringen. Varen skal returneres i original og ubrudt
        emballage. Kunden afholder fragtomkostninger ved forsendelsen til BEAT og bærer risikoen for
        Varen indtil modtagelsen.
        `
        ),
        m('p',
        `
        Varer, der returneres, skal være forsynet med et returnummer udleveret af BEAT. Returnummeret
        udleveres ved at kontakte b2bkundeservice@beat.dk med angivelse af Kundenavn og Kundenummer,
        samt begrundelse for returnering. Forsendelser der ikke er behørigt mærket med returnummer afvises.
        `
        ),
        m('p',
        `
        Kunden har ret til at få returneret et beløb svarende til den af Kunden erlagte pris for Varen,
        når BEAT har godkendt den returnerede Vare. Fragtomkostninger, forsikring, gebyrer mv.
        returneres ikke.
        `
        ),

    m('h4',
        '7. Reklamation samt fejl og mangler'
    ),
        m('p',
        `
        Kunden er forpligtet til straks og senest dagen efter modtagelsen af en leverance at foretage
        kontrol af Varen/Ydelsen samt at undersøge denne for eventuelle mangler. Transportskader skal
        straks gøres gældende overfor transportøren. Kunden er forpligtet til at gennemgå og afprøve
        software umiddelbart efter leveringen.
        `
        ),
        m('p',
        `
        BEAT hæfter alene for oprindelige fejl og mangler. Konstaterer Kunden fejl- og mangler, skal
        Kunden straks skriftligt reklamere til BEAT med specifikation af den mangel, der gøres gældende.
        Dette skal gøres til support@beat.dk. Reklameres der ikke rettidigt, er retten til at gøre
        mangler gældende bortfaldet.
        `
        ),
        m('p',
        `
        Reklamationsperioden er 24 måneder fra leveringen. Efter reklamationsperiodens udløb kan
        Kunden således ikke påberåbe sig manglen. For tredjepartsprodukter gælder den reklamationsfrist
        som fremgår af de for underleverandøren gældende reklamationsbestemmelser. Ombytning eller
        reparation medfører ikke, at der ændres på reklamationsperioden.
        `
        ),
        m('p',
        `
        BEATs ansvar for fejl og mangler er altid begrænset til efter BEATs valg enten at foretage
        afhjælpning henholdsvis omlevering indenfor rimelig tid.
        `
        ),
        m('p',
        `
        BEAT er uden ansvar for fejl og mangler som bl.a. skyldes ændringer foretaget af Kunden,
        reparation udført af andre end BEAT eller BEATs servicepartnere, software relaterede fejl,
        tab af data og manglende backup herunder i forbindelse med reparation, afhjælpning og service
        m.v., fejlagtig montering, behandling, vandskade, ild, ustabil strømforsyning, forkert
        nettilslutning eller følgefejl på grund af anvendelse af andet tilsluttet udstyr.
        `
        ),

    m('h4',
        '8. Erstatningsansvar og ansvarsbegrænsninger'
    ),
        m('p',
        `
        BEAT er ikke erstatningsansvarlig i tilfælde af forsinkelse eller mangler ved Varen/Ydelsen.
        BEAT fraskiver sig således ethvert ansvar for følgetab og/eller indirekte tab herunder
        driftstab, avancetab, tabt arbejdsfortjeneste, tab af data og/eller andre indirekte tab samt
        omkostninger i forbindelse med reetablering/opdatering mv. i anledning af mangler ved det solgte.
        `
        ),
        m('p',
        `
        BEATs produktansvar er begrænset til det ansvar som følger af produktansvarsloven, og BEAT
        fraskriver således ethvert ansvar for produktskader på andet grundlag. Kunden er forpligtet
        til uden ugrundet ophold skriftligt at meddele BEAT, hvis der er opstået en produktansvarsskade,
        eller at der er fare for, at en sådan skade vil indtræde.
        `
        ),
        m('p',
        `
        I intet tilfælde kan BEATs erstatningsansvar overstige et beløb svarende til 125% af prisen
        for den pågældende Varer/Ydelse i henhold til den af BEAT udstedte ordrebekræftelse.
        `
        ),


    m('h4',
        '9. Personoplysninger'
    ),
        m('p',
        `
        BEATs behandling af personoplysninger sker under iagttagelse af persondataloven. Kunden kan
        kontakte BEAT, såfremt der ønskes oplysninger om, hvilke data som behandles af Kunden eller
        hvis oplysningerne ønskes slettet eller korrigeret. Oplysninger om Kundens navn, adresse,
        e-mail mv. benyttes kun til at opfylde Kundens bestilling og informere Kunden, hvis uforudsete
        problemer skulle opstå. BEAT videregiver i intet tilfælde Kundens oplysninger til tredjemand,
        medmindre Kunden har accepteret dette.
        `
        ),

    m('h4',
        '10. Ophavsret og licensbetingelser for software'
    ),
        m('p',
        `
        Leverancer i form af software og musik-/video-filer er beskyttet efter ophavsretsloven og
        anden lovgivning. Uautoriseret kopiering, spredning eller offentlig fremførelse af sådanne
        leverancer er i strid med ophavsretsloven og kan resultere i civilretlige såvel som
        strafferetlige sanktioner.
        `
        ),
        m('p',
        `
        Kunden er til enhver tid forpligtet til at respektere de licensbetingelser og ophavsrettigheder
        som gælder for den leverende software og musik. Kunden er ansvarlig for tilsidesættelse af
        disse rettigheder herunder for uberettiget videregivelse til tredjemand. Kunden skal friholde
        BEAT for ethvert krav i anledning af Kundens tilsidesættelse af licensbetingelserne og
        ophavsrettighederne.
        `
        ),

    m('h4',
        '11. Afgifter vedrørende offentlig fremførelse af musik'
    ),
        m('p',
        `
        I de tilfælde, hvor Kunden benytter Varer/Ydelser fra BEAT til offentlig fremførelse af musik,
        er Kunden forpligtet til at afholde samtlige de afgifter til KODA og Gramex, der forfalder i
        anledning af offentlig fremførelse af musik i lokalerne. Kunden er således forpligtet i
        henhold til de til enhver tid gældende regler indenfor området.
        `
        ),

    m('h4',
        '12. Force Majeure'
    ),
        m('p',
        `
        Hvis der opstår en ekstraordinær situation, der er udenfor BEATs kontrol, og som efter
        købeloven må anses som force majeure, bliver BEATs forpligtelser suspenderet i det tidsrum,
        den ekstraordinære situation varer. I en sådan ekstraordinær situation kan Kunden opsige
        aftalen med BEAT, hvis situationen varer eller antages at ville vare længere end 60 dage,
        og i de tilfælde da kun med 14 dages varsel.
        `
        ),

    m('h4',
        '13. Lovvalg og værneting'
    ),
        m('p',
        `
        Enhver tvist mellem Kunden og BEAT i anledning af disse Vilkår afgøres efter dansk ret ved
        Sø- og Handelsretten i København.
        `
        ),

    m('h4',
        '14. Ugyldighed'
    ),
        m('p',
        `
        Såfremt en eller flere bestemmelser i disse Vilkår måtte blive anset for ugyldige, skal de
        øvrige vilkår fortsat være gyldige mellem Kunden og BEAT.
        `
        ),

    m('h4',
        '15. Andet'
    ),
        m('p',
        `

        `
        ),

    m('h4',
        '15.1 Overdragelse'
    ),
        m('p',
        `
        BEAT kan helt eller delvist overdrage og overføre sine rettigheder og forpligtelser til
        tredjemænd, herunder lade sine rettigheder og forpligtelser udføre helt eller delvis af
        underleverandører. Medmindre der er givet skriftligt samtykke fra BEAT må Kunden ikke
        overdrage rettigheder eller forpligtelse til tredjemand.
        `
        ),

)
